import React from 'react';
import { Typography, Container } from '@mui/material';
import logo from '../assets/propertee-logo.svg';

const MaintenancePage = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '75vh' }}>
      <Container style={{ textAlign: 'center' }}>
        <img src={logo} alt="Logo" style={{ height: '120px', borderRadius: '25px', marginBottom: '20px' }} />
        <Typography variant="h4" style={{ marginBottom: '20px' }}>Under Maintenance</Typography>
        <Typography variant="h5">We are currently undergoing scheduled maintenance. We'll be back soon!</Typography>
      </Container>
    </div>
  );
};

export default MaintenancePage;
