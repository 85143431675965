
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Box, IconButton, Typography } from '@mui/material';
import NewEventForm from './NewEventForm';
import EditEventForm from './EditEventForm';
import { getCalendarByAgentId, getHomeOpensByAgentId } from '../utilities/FirestoreFunctions';
import AuthContext from '../utilities/AuthContext';

import ProperteeCircularProgress from '../components/ProperteeCircular.js';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const localizer = momentLocalizer(moment);

function AppointmentManagement() {
  const { currentUser } = useContext(AuthContext);
  const [value, setValue] = useState(0);

  const [view, setView] = useState('month');
  const [date, setDate] = useState(new Date());
  const [st, setSt] = useState();
  const [et, setEt] = useState();

  const [events, setEvents] = useState([]);
  const onView = useCallback((newView) => setView(newView), [setView]);
  const onNavigate = useCallback((newDate) => setDate(newDate), [setDate]);

  const [loading, setLoading] = useState(true); // TODO: figure out what needs loading

  const [shouldRefetch, setShouldRefetch] = useState(true);

  const [editEvent, setEditEvent] = useState();

  // NOTE: i have moved this from useEffect to a use callback for efficiency
  //
  // const fetchEvents = () => {
  //   setLoading(true);
  //   Promise.all([
  //     getCalendarByAgentId(currentUser.uid),
  //     getHomeOpensByAgentId(currentUser.uid)
  //   ])
  //     .then(([calendarData, homeOpensData]) => {
  //       const getTypePrefix = (type) => {
  //         switch (type) {
  //           case 0: return "Meeting: ";
  //           case 1: return "Inspection: ";
  //           case 2: return "Home Open: ";
  //           case 3: return "Task: ";
  //           default: return "";
  //         }
  //       };
  //
  //       const calendarEvents = calendarData.map(event => ({
  //         ...event,
  //         start: moment(event.start.toDate()).toDate(),
  //         end: moment(event.end.toDate()).toDate(),
  //         title: getTypePrefix(event.resources.type) + event.title
  //       }));
  //
  //       const homeOpensEvents = homeOpensData.map(event => ({
  //         ...event,
  //         start: moment(event.start.toDate()).toDate(),
  //         end: moment(event.end.toDate()).toDate(),
  //         title: getTypePrefix(event.resources.type) + event.title
  //       }));
  //
  //       setEvents([...calendarEvents, ...homeOpensEvents]);
  //     })
  //     .catch(error => {
  //       console.error("Error fetching data:", error);
  //       setLoading(false);
  //       setShouldRefetch(false);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //       setShouldRefetch(false);
  //     });
  // };
  // useEffect(() => {
  //   if (shouldRefetch) {
  //     fetchEvents();
  //   }
  // }, [shouldRefetch, fetchEvents]);

  const fetchEvents = useCallback(() => {
    if (!currentUser?.uid) return; // Ensure currentUser is valid before making requests

    setLoading(true);
    Promise.all([
      getCalendarByAgentId(currentUser.uid),
      getHomeOpensByAgentId(currentUser.uid),
    ])
      .then(([calendarData, homeOpensData]) => {
        const getTypePrefix = (type) => {
          switch (type) {
            case 0:
              return "Meeting: ";
            case 1:
              return "Inspection: ";
            case 2:
              return "Home Open: ";
            case 3:
              return "Task: ";
            default:
              return "";
          }
        };

        const calendarEvents = calendarData.map((event) => ({
          ...event,
          start: moment(event.start.toDate()).toDate(),
          end: moment(event.end.toDate()).toDate(),
          title: getTypePrefix(event.resources.type) + event.title,
        }));

        const homeOpensEvents = homeOpensData.map((event) => ({
          ...event,
          start: moment(event.start.toDate()).toDate(),
          end: moment(event.end.toDate()).toDate(),
          title: getTypePrefix(event.resources.type) + event.title,
        }));

        setEvents([...calendarEvents, ...homeOpensEvents]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
        setShouldRefetch(false);
      });
  }, [currentUser?.uid]);

  useEffect(() => {
    if (shouldRefetch) {
      fetchEvents();
    }
  }, [shouldRefetch, fetchEvents]);

  const handleNewEventCreated = () => {
    setShouldRefetch(true);
  };

  const handleSelectedEvent = (event) => {
    setEditEvent(event);
    setValue(2);
  };

  const handleSelectSlot = ({ start, end, action }) => {
    if (action === "click") {
      if (view === 'month') {
        const selectedDateWithTime = new Date(start);
        selectedDateWithTime.setHours(8, 0, 0, 0);
        setDate(new Date(selectedDateWithTime));
        setView('day');
      }
      else {
        const dateFormat = "YYYY-MM-DD";
        const timeFormat = "HH:mm";
        let startDatetime = moment(start).format(dateFormat) + ` ${moment(start).format(timeFormat)}`;
        let endDatetime = moment(end).format(dateFormat) + ` ${moment(end).format(timeFormat)}`;

        setSt(startDatetime);
        setEt(endDatetime);
        setValue(1);
      }
    } else if (action === "select") {
      const dateFormat = "YYYY-MM-DD";
      const timeFormat = "HH:mm";
      let startDatetime = moment(start).format(dateFormat) + ` ${moment(start).format(timeFormat)}`;
      let endDatetime = moment(end).format(dateFormat) + ` ${moment(end).format(timeFormat)}`;

      setSt(startDatetime);
      setEt(endDatetime);
      setValue(1);
    }
  };

  return (
    <Box
      sx={{
        height: 'var(--router-height)',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        position: 'relative',
      }}
    >
      {/* Loading Spinner */}
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <ProperteeCircularProgress />
        </Box>
      )}

      {/* Header Section */}
      <Box>
        {value === 0 ? (
          <Typography variant="h4" align="center" gutterBottom sx={{ my: 1 }}>
            Appointment Management
          </Typography>
        ) : (
          <Box
            display="flex"
            height="30px"
            alignItems="center"
            p={2}
            bgcolor="#ffffff"
            boxShadow={1}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setValue(0)}
              aria-label="back"
            >
              <ArrowBackIcon />
            </IconButton>
          </Box>
        )}
      </Box>

      {/* Calendar Section */}
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
          padding: 2,
          display: value === 0 ? 'block' : 'none',
        }}
      >
        <Calendar
          localizer={localizer}
          events={events}
          onSelectEvent={handleSelectedEvent}
          startAccessor="start"
          endAccessor="end"
          selectable
          onSelectSlot={handleSelectSlot}
          view={view}
          date={date}
          onNavigate={onNavigate}
          onView={onView}
          style={{ height: '100%' }}
        />
      </Box>

      {/* Conditional Forms */}
      {value === 1 && (
        <NewEventForm
          setValue={setValue}
          st={st}
          et={et}
          onNewEventCreated={handleNewEventCreated}
        />
      )}
      {value === 2 && (
        <EditEventForm
          setValue={setValue}
          event={editEvent}
          onNewEventCreated={handleNewEventCreated}
        />
      )}
    </Box>
  );

}

export default AppointmentManagement;
