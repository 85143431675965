import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UploadControlBar from '../components/UploadControlBar';
import {
  Typography,
  Grid,
  Box,
  Checkbox,
  Divider,
  TextField,
  Paper,
  Snackbar,
  Alert,
  Slide,
  RadioGroup,
  FormControlLabel,
  Radio,
  Backdrop,
  LinearProgress,
  Button,
  IconButton,
} from '@mui/material';
import BedroomIcon from '@mui/icons-material/Bed';
import BathroomIcon from '@mui/icons-material/Bathtub';
import ParkingIcon from '@mui/icons-material/LocalParking';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DescriptionIcon from '@mui/icons-material/Description';
import ImageSliderUpload from '../components/ImageSliderUpload';
import EditDescriptionCard from '../components/EditDescriptionCard';
import CloseIcon from '@mui/icons-material/Close';
import { updateProperty, deleteProperty } from '../utilities/FirestoreFunctions';
import {
  //uploadImage,
  uploadMultipleImages,
  deleteImage,
  deletePropertyImages,
  uploadDocFile,
  uploadMultipleDocs,
  deleteDoc,
  deletePropertyDocs,
} from '../utilities/StorageFunctions';
import PlacesAutocomplete from './PlacesAutocomplete';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import ProperteeCircularProgress from '../components/ProperteeCircular.js';

const EditHome = ({ property, setCurrentComponent, updatePropertyInList, removeProperty }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!property) {
      navigate('/search');
    }
  }, [property, navigate]);

  const [propertyData, setPropertyData] = useState({
    ...property,
  });

  const [descriptionIsEmpty, setDescriptionIsEmpty] = useState(!property.description);
  const [editingField, setEditingField] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleRadioChange = (event, field) => {
    setPropertyData((prevData) => ({
      ...prevData,
      [field]: event.target.value,
    }));
  };

  const handleStartEditing = (field) => {
    setEditingField(field);
  };

  const handleCloseAutocomplete = () => {
    setEditingField(null);
  };

  const formatPriceDisplay = (price) => {
    return price ? `$${price.toLocaleString()}` : '';
  };

  const handleInputChange = (event, field) => {
    let value = event.target.value;

    if (['bedrooms', 'bathrooms', 'parking'].includes(field)) {
      value = value === '' ? '' : Math.max(0, parseInt(value) || 0);
    } else if (field === 'price') {
      value = parseInt(value.replace(/[^0-9]/g, '')) || '';
    } else {
      value = event.target.value;
    }

    setPropertyData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleBlur = (field) => {
    if (
      ['bedrooms', 'bathrooms', 'parking'].includes(field) &&
      propertyData[field] === ''
    ) {
      setPropertyData((prevData) => ({ ...prevData, [field]: 0 }));
    } else if (field === 'price' && propertyData[field] === '') {
      setPropertyData((prevData) => ({ ...prevData, [field]: 0 }));
    }
    handleConfirmChanges();
  };

  const handleSaveDescription = (editedDescription) => {
    setPropertyData((prevData) => ({
      ...prevData,
      description: editedDescription,
    }));
    setDescriptionIsEmpty(editedDescription.trim() === '');
    handleConfirmChanges();
  };

  const handleConfirmChanges = () => {
    setEditingField(null);
  };

  const handleImageChanges = (newImages) => {
    setPropertyData((prevData) => ({
      ...prevData,
      images: newImages,
    }));
  };

  const handleDeleteDoc = (index) => {
    setPropertyData((prev) => {
      const updatedDocs = [...prev.strataDocs];
      updatedDocs.splice(index, 1);
      return { ...prev, strataDocs: updatedDocs };
    });
  };

  const extractFileName = (doc) => {
    // If doc is already a File object, just return doc.name
    if (doc instanceof File) {
      return doc.name;
    }

    // Otherwise, doc is presumably a string URL; parse out the filename
    try {
      const urlObj = new URL(doc);
      const path = urlObj.pathname; // e.g. "/properties/abc123/mydocument.pdf"
      
      const indexOfO = path.indexOf('/o/');

      if (indexOfO !== -1) {
        // substring after "/o/"
        const encodedSegments = path.substring(indexOfO + 3); 
        // => "properties%2Fabc123%2Fmydocument.pdf"

        // 2) Decode so %2F becomes /
        const decodedPath = decodeURIComponent(encodedSegments); 
        // => "properties/abc123/mydocument.pdf"

        // 3) Return whatever is after the final slash
        return decodedPath.substring(decodedPath.lastIndexOf('/') + 1);
      }

      return doc;
    } catch (e) {
      // if parsing fails for any reason, just return the doc as-is
      return doc;
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (
        ['bedrooms', 'bathrooms', 'parking'].includes(editingField) &&
        propertyData[editingField] === ''
      ) {
        setPropertyData((prevData) => ({ ...prevData, [editingField]: 0 }));
      } else if (editingField === 'price' && propertyData[editingField] === '') {
        setPropertyData((prevData) => ({ ...prevData, [editingField]: 0 }));
      }
      handleConfirmChanges();
    }
  };

  const handleUpdateProperty = async () => {
    if (descriptionIsEmpty) {
      setSnackbar({
        open: true,
        message: 'Description cannot be empty',
        severity: 'warning',
      });
      return;
    } else {
      setLoading(true);
      setIsUploading(true);
      setUploadProgress(0);

      try {
        // Get the list of original images from property (passed as prop)
        const originalImages = property.images;

        // Get the current images from propertyData.images
        const currentImages = propertyData.images;

        // Determine which images are new (Files)
        const newImagesToUpload = currentImages.filter((img) => img instanceof File);

        // Upload new images and get their URLs
        let uploadedImageUrls = [];
        if (newImagesToUpload.length > 0) {
          uploadedImageUrls = await uploadMultipleImages(
            newImagesToUpload,
            propertyData.id,
            (progress) => {
              setUploadProgress(progress);
            }
          );
        }

        // Build the new images array
        const updatedImages = currentImages.map((img) => {
          if (img instanceof File) {
            // Replace File object with corresponding URL from uploadedImageUrls
            const index = newImagesToUpload.indexOf(img);
            return uploadedImageUrls[index];
          } else {
            // Keep existing URL
            return img;
          }
        });

        // Determine which images have been deleted
        const imagesToDelete = originalImages.filter(
          (imgUrl) => !updatedImages.includes(imgUrl)
        );

        // Delete images from storage
        for (const imgUrl of imagesToDelete) {
          await deleteImage(imgUrl);
        }

        // Update imgUrl if the first image has changed
        let imgUrl = propertyData.imgUrl;
        if (updatedImages[0] !== imgUrl) {
          imgUrl = updatedImages[0];
        }

        // Check if user removed any doc:
        const originalDocs = property.strataDocs || [];
        const currentDocs = propertyData.strataDocs;

        // figure out what's new (File objects) vs existing (URL strings)
        const docFilesToUpload = currentDocs.filter((doc) => doc instanceof File);
        const existingDocUrls = currentDocs.filter((doc) => typeof doc === 'string');
        
        // figure out which docs have been deleted:
        const docsToDelete = originalDocs.filter((oldDocUrl) => !existingDocUrls.includes(oldDocUrl));

        // Upload new doc files
        let newDocUrls = [];
        if (docFilesToUpload.length === 1) {
          newDocUrls.push(await uploadDocFile(docFilesToUpload[0], propertyData.id));
        } else if (docFilesToUpload.length > 1) {
          newDocUrls = await uploadMultipleDocs(docFilesToUpload, propertyData.id);
        }

        const finalDocUrls = [...existingDocUrls, ...newDocUrls];

        // delete from storage any docs in docsToDelete
        for (const oldDocUrl of docsToDelete) {
          await deleteDoc(oldDocUrl);
        }

        // Update propertyData with the new images array and imgUrl
        const updatedPropertyData = {
          ...propertyData,
          images: updatedImages,
          imgUrl: imgUrl,
          firstImageObject: propertyData.images[0],
          strataDocs: finalDocUrls,
        };

        // Update the property in Firestore
        await updateProperty(propertyData.id, updatedPropertyData);

        // Update the property in searchResults
        updatePropertyInList(updatedPropertyData);

        setSnackbar({
          open: true,
          message: 'Property updated successfully!',
          severity: 'success',
        });
        setTimeout(() => {
          setCurrentComponent('');
        }, 5000); // navigate after 5 seconds
      } catch (error) {
        console.error('Error updating property: ', error);
        setSnackbar({
          open: true,
          message: 'Failed to update property.',
          severity: 'error',
        });
      } finally {
        setIsUploading(false);
        setLoading(false);
      }
    }
  };

  const handleDeleteProperty = async () => {
    setLoading(true);
    setIsDeleting(true);
    try {
      // Delete images from storage
      if (propertyData.images.length > 0) {
        await deletePropertyImages(propertyData.id);
      }

      // Delete docs from storage
      if (propertyData.strataDocs.length > 0) {
        await deletePropertyDocs(propertyData.id);
      }

      // Delete property from Firestore
      await deleteProperty(propertyData.id);

      // Remove property from searchResults
      removeProperty(propertyData.id);

      setLoading(false);
      setSnackbar({
        open: true,
        message: 'Property deleted successfully!',
        severity: 'success',
      });
      setTimeout(() => {
        setCurrentComponent('');
      }, 5000); // navigate after 5 seconds
    } catch (error) {
      console.error('Error deleting property: ', error);
      setSnackbar({
        open: true,
        message: 'Failed to delete property.',
        severity: 'error',
      });
      setLoading(false);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
  }

  const isEditing = editingField !== null;

  const blueLineStyle = {
    borderBottom: '2px solid var(--accent-color)',
    width: '100%',
    marginTop: '8px',
    marginBottom: '8px',
  };

  return (
    <div>
      <UploadControlBar
        onBack={() => setCurrentComponent('')}
        onUpload={() => handleUpdateProperty()}
      />
      {/* Main Grid Container */}
      <Grid container>
        {/* Left Side (Images, Title, Description) */}
        <Grid
          item
          xs={12}
          md={9}
          order={{ xs: 1, md: 1 }}
          sx={{
            borderRight: { md: '2px solid var(--accent-color)' },
            borderBottom: { xs: '2px solid var(--accent-color)', md: 'none' },
          }}
        >
          <Grid container direction="column" spacing={2}>
            {/* Images */}
            <Grid item>
              <ImageSliderUpload
                images={propertyData.images}
                setNewImages={handleImageChanges}
              />
            </Grid>
            {/* Title and Short Description */}
            <Grid item>
              <Paper
                elevation={0}
                sx={{
                  position: 'relative',
                  maxWidth: 1000,
                  margin: 'auto',
                  backgroundColor: 'transparent',
                  border: 'none',
                  padding: 3,
                }}
              >
                <Grid container direction="column" spacing={0}>
                  {/* Title */}
                  <Grid item>
                    {editingField === 'title' ? (
                      <TextField
                        fullWidth
                        value={propertyData.title}
                        onChange={(e) => handleInputChange(e, 'title')}
                        onBlur={handleConfirmChanges}
                        onKeyPress={handleKeyPress}
                        autoFocus
                        error={!propertyData.title.trim() && isEditing}
                        helperText={
                          !propertyData.title.trim() && isEditing
                            ? 'Title cannot be empty'
                            : ''
                        }
                        size="small"
                        sx={{
                          '& .MuiInputBase-input': {
                            fontSize: '2.05rem',
                            fontWeight: 'bold',
                          },
                        }}
                      />
                    ) : (
                      <Typography
                        variant="h3"
                        onClick={() => handleStartEditing('title')}
                        sx={{
                          color:
                            !propertyData.title.trim() && !isEditing
                              ? 'var(--warning)'
                              : 'inherit',
                          cursor: 'pointer',
                          fontWeight: 'bold',
                          marginBottom: '8px',
                        }}
                      >
                        {!propertyData.title
                          ? 'Click to add a title'
                          : propertyData.title}
                      </Typography>
                    )}
                  </Grid>

                  {/* Short Description */}
                  <Grid item>
                    {editingField === 'shortDescription' ? (
                      <TextField
                        fullWidth
                        value={propertyData.shortDescription}
                        onChange={(e) => handleInputChange(e, 'shortDescription')}
                        onBlur={handleConfirmChanges}
                        onKeyPress={handleKeyPress}
                        autoFocus
                        error={!propertyData.shortDescription.trim() && isEditing}
                        helperText={
                          !propertyData.shortDescription.trim() && isEditing
                            ? 'Short description cannot be empty'
                            : ''
                        }
                        size="small"
                        sx={{
                          '& .MuiInputBase-input': {
                            fontSize: '1.25rem',
                            color: 'text.secondary',
                          },
                        }}
                      />
                    ) : (
                      <Typography
                        variant="h5"
                        onClick={() => handleStartEditing('shortDescription')}
                        sx={{
                          color:
                            !propertyData.shortDescription.trim() && !isEditing
                              ? 'var(--warning)'
                              : 'text.secondary',
                          cursor: 'pointer',
                        }}
                      >
                        {!propertyData.shortDescription
                          ? 'Click to add a short description'
                          : propertyData.shortDescription}
                      </Typography>
                    )}
                  </Grid>

                  {/* Blue Line */}
                  <div style={blueLineStyle}></div>
                </Grid>
              </Paper>
            </Grid>
            {/* Description */}
            <Grid item>
              <EditDescriptionCard
                property={propertyData}
                onSaveDescription={handleSaveDescription}
                onEditAddress={() => handleStartEditing('address')}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Right Side (Property Info) */}
        <Grid
          item
          xs={12}
          md={3}
          order={{ xs: 2, md: 2 }}
          sx={{
            position: { md: 'sticky' },
            alignSelf: 'flex-start',
            padding: 3,
          }}
        >
          <Box className="property-info">
            {/* Address */}
            <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
              <LocationOnIcon sx={{ mr: 1 }} />
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Address
              </Typography>
            </Box>
            {editingField === 'address' ? (
              <PlacesAutocomplete
                setPropertyData={setPropertyData}
                closeAutocomplete={handleCloseAutocomplete}
              />
            ) : (
              <Typography
                variant="body1"
                onClick={() => handleStartEditing('address')}
                sx={{
                  color:
                    !propertyData.address.trim() && !isEditing
                      ? 'var(--warning)'
                      : 'inherit',
                  cursor: 'pointer',
                }}
              >
                {!propertyData.address
                  ? 'Click to select address'
                  : propertyData.address}
              </Typography>
            )}
            <Divider
              style={{
                margin: '16px 0',
                borderColor: 'var(--accent-color)',
              }}
            />
            {/* Listing Type */}
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Listing Type
            </Typography>
            <RadioGroup
              row
              name="listingType"
              value={propertyData.listingType}
              onChange={(e) => handleRadioChange(e, 'listingType')}
            >
              <FormControlLabel value="1" control={<Radio />} label="For Sale" />
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="Casual Listing"
              />
              <FormControlLabel value="-1" control={<Radio />} label="Off Market" />
            </RadioGroup>
            <Divider
              style={{ margin: '16px 0', borderColor: 'var(--accent-color)' }}
            />
            {/* Price Parameters */}
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Price Parameters
            </Typography>
            <RadioGroup
              row
              name="priceParameter"
              value={propertyData.priceParameter}
              onChange={(e) => handleRadioChange(e, 'priceParameter')}
            >
              <FormControlLabel
                value="="
                control={<Radio />}
                label="Listed price"
              />
              <FormControlLabel
                value=">"
                control={<Radio />}
                label="Offers higher than"
              />
              <FormControlLabel
                value="*"
                control={<Radio />}
                label="Open to offers"
              />
            </RadioGroup>
            <Divider
              style={{ margin: '16px 0', borderColor: 'var(--accent-color)' }}
            />
            {/* Price */}
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 1 }}
            >
              <AttachMoneyIcon sx={{ mr: 1 }} />
              Price
            </Typography>
            {editingField === 'price' && propertyData.priceParameter !== '*' ? (
              <TextField
                type="number"
                inputProps={{ min: 0 }}
                value={
                  propertyData.price !== '' ? propertyData.price.toString() : '0'
                }
                onChange={(e) => handleInputChange(e, 'price')}
                onBlur={() => {
                  handleBlur('price');
                  if (propertyData.price === '') {
                    handleInputChange({ target: { value: '0' } }, 'price');
                  }
                }}
                onKeyPress={handleKeyPress}
                autoFocus
                error={propertyData.price === '' && isEditing}
                helperText={
                  propertyData.price === '' && isEditing
                    ? 'Price cannot be empty'
                    : ''
                }
                size="small"
                style={{
                  width: '100%',
                  color:
                    propertyData.price === '' && !isEditing
                      ? 'var(--warning)'
                      : 'inherit',
                  borderColor:
                    propertyData.price === ''
                      ? 'var(--warning)'
                      : 'var(--accent-color)',
                }}
              />
            ) : (
              <Typography
                onClick={() => handleStartEditing('price')}
                style={{
                  color:
                    (propertyData.price === '' || propertyData.price === '0') &&
                      !isEditing &&
                      propertyData.priceParameter !== '*'
                      ? 'var(--warning)'
                      : 'inherit',
                  cursor: 'pointer',
                }}
              >
                {(propertyData.price === '' || propertyData.price === '0') &&
                  propertyData.priceParameter !== '*' ? (
                  'Click to add price.'
                ) : propertyData.priceParameter === '*' ? (
                  'Open to offers'
                ) : (
                  formatPriceDisplay(propertyData.price)
                )}
              </Typography>
            )}
            <Divider
              style={{
                margin: '16px 0',
                borderColor: 'var(--accent-color)',
              }}
            />
            {/* Bedrooms */}
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                mb: 1,
              }}
            >
              <BedroomIcon sx={{ mr: 1 }} />
              Bedrooms
            </Typography>
            {editingField === 'bedrooms' ? (
              <TextField
                type="number"
                inputProps={{ min: 0 }}
                value={propertyData.bedrooms}
                onChange={(e) => handleInputChange(e, 'bedrooms')}
                onBlur={() => handleBlur('bedrooms')}
                onKeyPress={handleKeyPress}
                autoFocus
                error={propertyData.bedrooms === '' && isEditing}
                helperText={
                  propertyData.bedrooms === '' && isEditing
                    ? 'Bedrooms cannot be empty'
                    : ''
                }
                size="small"
                style={{
                  width: '100%',
                  color:
                    propertyData.bedrooms === '' && !isEditing
                      ? 'var(--warning)'
                      : 'inherit',
                  borderColor:
                    propertyData.bedrooms === ''
                      ? 'var(--warning)'
                      : 'var(--accent-color)',
                }}
              />
            ) : (
              <Typography
                onClick={() => handleStartEditing('bedrooms')}
                style={{
                  color:
                    propertyData.bedrooms === '' ? 'var(--warning)' : 'inherit',
                  cursor: 'pointer',
                }}
              >
                {propertyData.bedrooms === ''
                  ? 'Click to enter bedrooms'
                  : `${propertyData.bedrooms} ${propertyData.bedrooms === 1 ? 'Bed' : 'Beds'
                  }`}
              </Typography>
            )}
            <Divider
              style={{
                margin: '16px 0',
                borderColor: 'var(--accent-color)',
              }}
            />
            {/* Bathrooms */}
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                mb: 1,
              }}
            >
              <BathroomIcon sx={{ mr: 1 }} />
              Bathrooms
            </Typography>
            {editingField === 'bathrooms' ? (
              <TextField
                type="number"
                inputProps={{ min: 0 }}
                value={propertyData.bathrooms}
                onChange={(e) => handleInputChange(e, 'bathrooms')}
                onBlur={() => handleBlur('bathrooms')}
                onKeyPress={handleKeyPress}
                autoFocus
                error={propertyData.bathrooms === '' && isEditing}
                helperText={
                  propertyData.bathrooms === '' && isEditing
                    ? 'Bathrooms cannot be empty'
                    : ''
                }
                size="small"
                style={{
                  width: '100%',
                  color:
                    propertyData.bathrooms === '' && !isEditing
                      ? 'var(--warning)'
                      : 'inherit',
                  borderColor:
                    propertyData.bathrooms === ''
                      ? 'var(--warning)'
                      : 'var(--accent-color)',
                }}
              />
            ) : (
              <Typography
                onClick={() => handleStartEditing('bathrooms')}
                style={{
                  color:
                    propertyData.bathrooms === '' ? 'var(--warning)' : 'inherit',
                  cursor: 'pointer',
                }}
              >
                {propertyData.bathrooms === ''
                  ? 'Click to enter bathrooms'
                  : `${propertyData.bathrooms} ${propertyData.bathrooms === 1 ? 'Bath' : 'Baths'
                  }`}
              </Typography>
            )}
            <Divider
              style={{
                margin: '16px 0',
                borderColor: 'var(--accent-color)',
              }}
            />
            {/* Parking Spots */}
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                mb: 1,
              }}
            >
              <ParkingIcon sx={{ mr: 1 }} />
              Parking Spots
            </Typography>
            {editingField === 'parking' ? (
              <TextField
                type="number"
                inputProps={{ min: 0 }}
                value={propertyData.parking}
                onChange={(e) => handleInputChange(e, 'parking')}
                onBlur={() => handleBlur('parking')}
                onKeyPress={handleKeyPress}
                autoFocus
                error={propertyData.parking === '' && isEditing}
                helperText={
                  propertyData.parking === '' && isEditing
                    ? 'Parking spots cannot be empty'
                    : ''
                }
                size="small"
                style={{
                  width: '100%',
                  color:
                    propertyData.parking === '' && !isEditing
                      ? 'var(--warning)'
                      : 'inherit',
                  borderColor:
                    propertyData.parking === ''
                      ? 'var(--warning)'
                      : 'var(--accent-color)',
                }}
              />
            ) : (
              <Typography
                onClick={() => handleStartEditing('parking')}
                style={{
                  color:
                    propertyData.parking === '' ? 'var(--warning)' : 'inherit',
                  cursor: 'pointer',
                }}
              >
                {propertyData.parking === ''
                  ? 'Click to enter parking spots'
                  : `${propertyData.parking} Parking`}
              </Typography>
            )}
            <Divider
              style={{
                margin: '16px 0',
                borderColor: 'var(--accent-color)',
              }}
            />
            {/* Strata Info */}
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 1 }}
            >
              <DescriptionIcon sx={{ mr: 1 }} />
              Strata Documents
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={propertyData.isStrata}
                  onChange={(e) =>
                    setPropertyData((prev) => ({ ...prev, isStrata: e.target.checked }))
                  }
                />
              }
              label="Is this a Strata Property?"
            />
            
            {/* Only show doc upload if isStrata is true */}
            {propertyData.isStrata && (
              <>
                <Typography sx={{ mt: 2 }}>
                  Please upload Strata Documents
                </Typography>
                <Button
                  variant="containedAccent"
                  fullWidth
                  onClick={() => document.getElementById('strata-doc-upload').click()}
                  sx={{ 
                    mt: 1,
                    backgroundColor: 'var(--accent-color)',
                    '&:hover': {
                      backgroundColor: 'var(--accent-color-dark)',
                    },
                    color: 'white',
                  }}
                >
                  Upload PDF/Word
                </Button>
                <input
                  id="edit-doc-upload"
                  type="file"
                  accept=".pdf,.doc,.docx"
                  style={{ display: 'none' }}
                  multiple
                  onChange={(e) => {
                    const newFiles = [...propertyData.strataDocs, ...Array.from(e.target.files)];
                    setPropertyData((prev) => ({ ...prev, strataDocs: newFiles }));
                  }}
                />
                {/* Display currently selected docs (if any) */}
                {propertyData.strataDocs.length > 0 ? propertyData.strataDocs.map((doc, idx) => {
                  const isFile = doc instanceof File;
                  const name = isFile ? doc.name : doc; // if it’s a File, doc.name; if it’s a string (URL), doc
                  const displayName = extractFileName(name);
                  
                  return (
                    <Box
                      key={idx}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt={1}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          maxWidth: '70%',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        title={name} // so user can see full name on hover
                      >
                        {displayName}
                      </Typography>

                      {/* Delete button/icon */}
                      <IconButton
                        onClick={() => handleDeleteDoc(idx)}
                        size="small" // optional, to keep it subtle
                        aria-label="Remove doc"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  );
                }) : null}
              </>
            )}
            <Divider
              style={{
                margin: '16px 0',
                borderColor: 'var(--accent-color)',
              }}
            />
            {/* Delete Property Button */}
            <Button
              variant="warning"
              onClick={handleDeleteProperty}
              fullWidth
              sx={{ mt: 2 }}
            >
              Delete Property
            </Button>
          </Box>
        </Grid>
      </Grid>
      {/* Backdrop and Snackbar */}
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        }}
        open={isUploading || loading || isDeleting}
      >
        <div style={{ width: '80%', textAlign: 'center' }}>
          {isUploading && (
            <>
              <Typography variant="h6" color="#ffffff">
                Uploading images...
              </Typography>
              <LinearProgress
                variant="determinate"
                value={uploadProgress}
                sx={{
                  mt: 2,
                  color: '#ffffff',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: 'accent.main',
                  },
                }}
              />
              <Typography variant="body1" sx={{ mt: 1, color: '#ffffff' }}>
                {Math.round(uploadProgress)}%
              </Typography>
            </>
          )}
          {isDeleting && (
            <>
              <Typography variant="h6" color="#ffffff">
                Deleting property...
              </Typography>
              <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'var(--router-height)' }}>
                <ProperteeCircularProgress />
              </Box>
            </>
          )}
        </div>
      </Backdrop>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EditHome;
