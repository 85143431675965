import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import {
  Grid,
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Paper,
  Select,
  CircularProgress,
  Container,
  InputLabel,
  IconButton,
  Typography,
  TextField,
  FormControl,
  FormHelperText,
  Menu,
  MenuItem,
  InputAdornment,
  Snackbar, 
  Alert,
  Checkbox,
  Divider,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { updateEvent, deleteEvent, updateHomeOpen, deleteHomeOpen, updateInspection, getSinglePropertyById, getAttendanceRegisterByHomeOpenId } from '../utilities/FirestoreFunctions';
import AuthContext from '../utilities/AuthContext';
import HomeOpenQR from './HomeOpenQR';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh'
import SettingsIcon from '@mui/icons-material/Settings';

import ProperteeCircularProgress from '../components/ProperteeCircular.js';

const EditEventForm = ({ setValue, event, onNewEventCreated, showSnackbar }) => {
  const { currentUser } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [attendees, setAttendees] = useState([]);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const [agentDetails, setAgentDetails] = useState({
    name: '',
    email: '',
    phone: ''
  });

  //for sorting
  const [sortField, setSortField] = useState(null); // 'name', 'email', 'offer'
  const [sortOrder, setSortOrder] = useState("asc"); // 'asc' or 'desc'

  const [visibleColumns, setVisibleColumns] = useState({
    name: true,
    phone: true,
    email: true,
    offer: true,
    financeType: true,
    settlement: true,
    termite: true,
    otherConditions: true,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const eventId = event.resources.id;
  let eventTitle = '';
  if (event.title.includes('Meeting: ')) {
    eventTitle = event.title.replace('Meeting: ', '');
  }
  else if (event.title.includes('Inspection: ')) {
    eventTitle = event.title.replace('Inspection: ', '');
  }
  else if (event.title.includes('Home Open: ')) {
    eventTitle = event.title.replace('Home Open: ', '');
  }
  else if (event.title.includes('Task: ')) {
    eventTitle = event.title.replace('Task: ', '');
  }
  else {
    eventTitle = event.title;
  }

  let formFields = {};
  if (event.resources.type === 0 || event.resources.type === 3 || event.resources.type === 4) {
    formFields = {
      eventType: event.resources.type ? event.resources.type : 0,
      title: event.title ? eventTitle : '',
      start: event.start ? dayjs(event.start) : null,
      end: event.end ? dayjs(event.end) : null,
    };
  }
  else {
    formFields = {
      eventType: event.resources.type ? event.resources.type : 0,
      title: event.title ? eventTitle : '',
      start: event.start ? dayjs(event.start) : null,
      end: event.end ? dayjs(event.end) : null,
      propertyId: event.resources.propertyId ? event.resources.propertyId : '',
      address: event.resources.event.address ? event.resources.event.address : '',
    };
  }

  const [formData, setFormData] = useState({
    ...formFields
  });

  const [errors, setErrors] = useState({
    start: false,
    end: false,
  });

  // Initialize propertyData with a structure that matches expected fields
  const [propertyData, setPropertyData] = useState({
    price: 0,
    bedrooms: 0,
    bathrooms: 0,
    parkings: 0,
  });

  const [formError, setFormError] = useState('');
  const [loading, setLoading] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success', // 'success', 'error', 'warning', 'info'
  });

  const fetchPropertyData = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getSinglePropertyById(event.resources.propertyId);
      setPropertyData({
        price: data.price,
        bedrooms: data.bedrooms,
        bathrooms: data.bathrooms,
        parkings: data.parking,
        images: data.images,
        priceParameter: data.priceParameter,
      });
    } catch (error) {
      console.error('Error fetching property data:', error);
    }
    setLoading(false);
  }, [event.resources.propertyId]);
  
  const fetchAttendanceRegister = useCallback(async () => {
    try {
      setLoading(true);
      const register = await getAttendanceRegisterByHomeOpenId(event.resources.id);
      setAttendees(formatPrices(register));
    } catch (error) {
      console.error('Error fetching attendance register:', error);
    }
    setLoading(false);
  }, [event.resources.id]);

  const refreshAttendanceRegister = useCallback(async () => {
    setIsRefreshing(true);  // This is triggered by the button, so we only disable the button
    try {
      const register = await getAttendanceRegisterByHomeOpenId(event.resources.id);
      setAttendees(formatPrices(register));
    } catch (error) {
      console.error('Error refreshing attendance register:', error);
    }
    setIsRefreshing(false);
  }, [event.resources.id]);

  const loadData = useCallback(async () => {
    setLoading(true);
  
    if (event.resources.type === 1 || event.resources.type === 2) {
      await fetchPropertyData();
    } else {
      setLoading(false);
    }
  
    if (event.resources.type === 2) {
      await fetchAttendanceRegister();
    }
  
    const agentName = currentUser.firstname + ' ' + currentUser.lastname;
    setAgentDetails({
      name: agentName,
      email: currentUser.email,
      phoneNumber: currentUser.phoneNumber?.replace(/(\+61)(\d{3})(\d{3})(\d{3})/, "$1 $2 $3 $4") || '',
    });
  
    setLoading(false);
  }, [currentUser, event.resources.type, fetchPropertyData, fetchAttendanceRegister]);
  
  useEffect(() => {
    loadData();
  }, [loadData]);

  const validateTitle = (title) => {
    if (title === '') {
      return false;
    }

    return true;
  };

  const validateStartDate = (date) => {
    if (date === null) {
      return false;
    }

    if (new Date(date.toISOString()) < new Date()) {
      return false;
    }

    return true;
  };

  const validateEndDate = (date) => {
    if (date === null) {
      return false;
    }

    if (new Date(date.toISOString()) <= new Date(formData.start.toISOString())) {
      return false;
    }

    return true;
  };

  const formatPrices = (register) => {
    return register.map((attendee) => {
      if (attendee.offer) {
        attendee.offer = `$${Number(attendee.offer).toLocaleString()}`;
      }
      return attendee;
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Clear errors as the user types
    setErrors({
      ...errors,
      [name]: false
    });

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleChange = (event) => {
    // Clear errors as the user types
    setErrors({
      ...errors,
      eventType: false
    });

    setFormData({
      ...formData,
      eventType: event.target.value
    });
  };

  const handleStartPickerChange = (value) => {
    // Clear errors as the user types
    setErrors({
      ...errors,
      start: false
    });

    setFormData(prevFormData => ({
      ...prevFormData,
      start: value ? value : null
    }));
  };

  const handleEndPickerChange = (value) => {
    // Clear errors as the user types
    setErrors({
      ...errors,
      end: false
    });

    setFormData(prevFormData => ({
      ...prevFormData,
      end: value ? value : null
    }));
  };

  const handleClick = () => {
    setValue(0);
  }

  const handleDeleteClick = async () => {
    setLoading(true);
    try {
      if (formData.eventType === 2) {
        await deleteHomeOpen(eventId);
        console.log('Successfully deleted a home open.');
      }
      else {
        await deleteEvent(currentUser.uid, eventId);
        console.log('Successfully deleted an event.');
      }
      onNewEventCreated(); // Trigger event re-fetch
      setFormError('');
    } catch (error) {
      console.error('Error deleting event: ', error);
      setFormError("An unexpected error occurred. Please try again later.");
    }
    setLoading(false);
    setValue(0);
  }

  const handleSaveClick = async () => {
    let valid = true;

    if ((formData.eventType === 0 || formData.eventType === 3 || formData.eventType === 4) && !validateTitle(formData.title)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: true
      }));
      valid = false;
    }

    if (!validateStartDate(formData.start)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        start: true
      }));
      valid = false;
    }

    if (!validateEndDate(formData.end)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        end: true
      }));
      valid = false;
    }

    if (valid) {
      setLoading(true);
      try {
        if (formData.eventType === 0) {
          await updateEvent(currentUser.uid, eventId, formData.title, formData.start, formData.end);
          console.log('Successfully updated a meeting.');
          onNewEventCreated(); // Trigger event re-fetch
        } else if (formData.eventType === 1) {
          await updateInspection(currentUser.uid, eventId, formData.start, formData.end);
          console.log('Successfully updated an inspection.');
          onNewEventCreated(); // Trigger event re-fetch
        } else if (formData.eventType === 2) {
          await updateHomeOpen(eventId, formData.start, formData.end);
          console.log('Successfully updated an home open.');
          onNewEventCreated(); // Trigger event re-fetch
        } else if (formData.eventType === 3) {
          await updateEvent(currentUser.uid, eventId, formData.title, formData.start, formData.end);
          console.log('Successfully updated a task.');
          onNewEventCreated(); // Trigger event re-fetch   
        } else {
          await updateEvent(currentUser.uid, eventId, formData.title, formData.start, formData.end);
          console.log('Successfully updated an event.');
          onNewEventCreated(); // Trigger event re-fetch
        }
        setFormError('');
      } catch (error) {
        console.error('Error saving event: ', error);
        setFormError("An unexpected error occurred. Please try again later.");
      }
      setLoading(false);
      setValue(0);
    }
  }

  const rowStyle = {
    '&:nth-of-type(odd)': {
      backgroundColor: '   rgba(173, 216, 230, 0.2)', // light blue color
    },
  };

  const toggleUpdateForm = () => {
    setShowUpdateForm((prev) => !prev);
  };

  const filteredAttendees = useMemo(() => {
    const keywords = searchTerm.toLowerCase().split(/\s+/).filter(Boolean);
    return attendees.filter(attendee =>
      keywords.every(keyword =>
        Object.values(attendee).some(value =>
          typeof value === 'string' && value.toLowerCase().includes(keyword)
        )
      )
    );
  }, [attendees, searchTerm]);

  const handleShowSnackbar = (message, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };
  
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const capitalizeText = (text) => {
    return text ? text.charAt(0).toUpperCase() + text.slice(1).toLowerCase() : "";
  };

  const toggleColumn = (column) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  //====Sorting for the attendees table

  // Function to handle sorting logic
  const handleSort = (field) => {
    if (sortField === field) {
      // Toggle sort order if the same field is clicked
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      // Set new sort field and default to ascending order
      setSortField(field);
      setSortOrder("asc");
    }
  };

  // Function to sort attendees

  const sortedAttendees = useMemo(() => {
    if (!sortField) return filteredAttendees; // Return unfiltered if no sortField is set

    return [...filteredAttendees].sort((a, b) => {
      const valueA = a[sortField] || ""; // Fallback to empty string if undefined
      const valueB = b[sortField] || ""; // Fallback to empty string if undefined

      if (sortField === "offer") {
        // Handle "N/A" values and numeric comparison
        const numA = parseFloat(valueA.replace(/[^\d.-]/g, ""));
        const numB = parseFloat(valueB.replace(/[^\d.-]/g, ""));

        const isValidA = !isNaN(numA) && valueA !== "N/A";
        const isValidB = !isNaN(numB) && valueB !== "N/A";

        if (isValidA && isValidB) {
          // Both are valid numbers, compare them numerically
          return sortOrder === "asc" ? numA - numB : numB - numA;
        } else if (isValidA) {
          // Only numA is valid, it comes before numB
          return -1;
        } else if (isValidB) {
          // Only numB is valid, it comes before numA
          return 1;
        } else {
          // Neither are valid numbers, consider them equal
          return 0;
        }
      }

      // Sort other fields (e.g., strings alphabetically)
      return sortOrder === "asc"
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
  }, [filteredAttendees, sortField, sortOrder]);


  //====End sorting for the end table


  if (loading) {
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'var(--router-height)' }}>
        <ProperteeCircularProgress />
      </Box>
    );
  }
  else {
    return (
      <>
        <Container maxWidth={formData.eventType === 2 ? 'lg' : 'sm'}>
          <Paper elevation={3} sx={{ my: 2, p: 3 }}>
            <Grid container justifyContent="center" alignItems={formData.eventType === 2 ? "flex-start" : "center"}>
              <Grid
                item={formData.eventType === 2}
                xs={formData.eventType === 2 ? 12 : undefined}
                md={formData.eventType === 2 ? 8 : undefined}
                lg={formData.eventType === 2 ? 8 : undefined}>
                {showUpdateForm || formData.eventType === 1 || formData.eventType === 0 || formData.eventType === 3 || formData.eventType === 4 ? (
                  <Box sx={{ px: 2 }}>
                    <Box width="100%">
                      <Typography variant="h4" align="center" sx={{ fontWeight: "bold" }}>
                        Update Event
                      </Typography>
                    </Box>
                    <Box component="form" noValidate sx={{ mt: 2, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <FormControl variant="outlined" margin="dense" sx={{ width: '100%' }}>
                        <InputLabel>Event Type</InputLabel>
                        <Select
                          value={formData.eventType}
                          label="Event Type"
                          onChange={handleChange}
                          disabled
                        >
                          <MenuItem value={0}>Meeting</MenuItem>
                          <MenuItem value={1}>Inspection</MenuItem>
                          <MenuItem value={2}>Home Open</MenuItem>
                          <MenuItem value={3}>Task</MenuItem>
                          <MenuItem value={4}>Other</MenuItem>
                        </Select>
                      </FormControl>

                      {(formData.eventType === 0 || formData.eventType === 3 || formData.eventType === 4) && (
                        <FormControl variant="outlined" margin="dense" sx={{ width: '100%' }}>
                          <TextField
                            label="Title"
                            name="title"
                            variant="outlined"
                            value={formData.title}
                            onChange={handleInputChange}
                            inputProps={{ style: { backgroundColor: '#ffffff' } }}
                          />
                          {errors.title && <FormHelperText>Title must not be empty</FormHelperText>}
                        </FormControl>
                      )}

                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                          gap: 1
                        }}>
                        <FormControl error={errors.start} variant="outlined" margin="dense" sx={{ width: '100%' }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              label="Start Time"
                              name="start"
                              variant="outlined"
                              value={formData.start}
                              onChange={handleStartPickerChange}
                              format="DD/MM/YYYY HH:mm"
                              sx={{ backgroundColor: 'background.datepicker' }}
                            />
                            {errors.start && <FormHelperText>Date must be valid, i.e. in the future</FormHelperText>}
                          </LocalizationProvider>
                        </FormControl>
                        <FormControl error={errors.end} variant="outlined" margin="dense" sx={{ width: '100%' }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              label="End Time"
                              name="end"
                              variant="outlined"
                              value={formData.end}
                              onChange={handleEndPickerChange}
                              format="DD/MM/YYYY HH:mm"
                              sx={{ backgroundColor: 'background.datepicker' }}
                            />
                            {errors.end && <FormHelperText>Date and time must be after the start time.</FormHelperText>}
                          </LocalizationProvider>
                        </FormControl>
                      </Box>
                      {(formData.eventType === 1 || formData.eventType === 2) && (
                        <FormControl variant="outlined" margin="dense" sx={{ width: '100%' }}>
                          <InputLabel>Property</InputLabel>
                          <Select
                            label="Property"
                            name="propertyId"
                            value={formData.propertyId}
                            disabled
                          >
                            <MenuItem key={formData.propertyId} value={formData.propertyId}>
                              {formData.address}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                      <Button
                        onClick={handleSaveClick}
                        variant="containedAccent"
                        sx={{
                          width: "100%",
                          my: 1
                        }}
                      >
                        {loading ? <CircularProgress size={24} /> : 'Save'}
                      </Button>

                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                          gap: 1
                        }}>
                        <Button
                          onClick={() => formData.eventType === 2 ? toggleUpdateForm() : handleClick()}
                          variant="outlined"
                          sx={{
                            width: "100%",
                          }}
                        >
                          {formData.eventType === 2 ? "Back" : "Cancel"}
                        </Button>
                        <Button
                          onClick={() => { handleDeleteClick(); }}
                          disabled={attendees.length > 0 ? true : false}
                          variant="warning"
                          sx={{ width: "100%" }}
                        >
                          Delete
                        </Button>
                      </Box>
                      {formError && <Typography color="error" sx={{ mt: 1 }}>{formError}</Typography>}
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    {(formData.eventType === 2) && propertyData && (
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h4" sx={{ alignSelf: 'center', fontWeight: 'bold', mb: 2 }}>Attendance Register</Typography>

                        {attendees.length > 0 ? (
                          <>
                            <TextField
                              variant="outlined"
                              size="small"
                              placeholder="Search..."
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              sx={{ mb: 2 }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={refreshAttendanceRegister} disabled={isRefreshing}>
                                      {isRefreshing ? (
                                        <CircularProgress size={20} />
                                      ) : (
                                        <RefreshIcon />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />

                            <TableContainer component={Paper} sx={{ flex: 1, maxHeight: "530px", overflowY: "auto", mb: 2 }}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    {visibleColumns.name && 
                                      <TableCell onClick={() => handleSort("name")} style={{ minWidth: "100px", cursor: "pointer" }}>
                                        Name {sortField === "name" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
                                      </TableCell>
                                    }
                                    {visibleColumns.phone &&
                                      <TableCell onClick={() => handleSort("phone")} style={{ cursor: "pointer" }}>
                                        Phone
                                      </TableCell>
                                    }
                                    {visibleColumns.email &&
                                      <TableCell onClick={() => handleSort("email")} style={{ cursor: "pointer" }}>
                                        Email {sortField === "email" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
                                      </TableCell>
                                    }
                                    {visibleColumns.offer &&
                                      <TableCell onClick={() => handleSort("offer")} style={{ cursor: "pointer" }}>
                                        Offer {sortField === "offer" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
                                      </TableCell>
                                    }
                                    {visibleColumns.financeType &&
                                      <TableCell>
                                        Finance Type
                                      </TableCell>
                                    }
                                    {visibleColumns.settlement &&
                                      <TableCell>
                                        Subject to Settlement
                                      </TableCell>
                                    }
                                    {visibleColumns.termite &&
                                      <TableCell>
                                        Termite Inspection
                                      </TableCell>
                                    }
                                    {visibleColumns.otherConditions &&
                                      <TableCell>
                                        Other Conditions
                                      </TableCell>
                                    }
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {sortedAttendees.map((attendee) => (
                                    <TableRow key={attendee.id} sx={rowStyle}>
                                      {visibleColumns.name && 
                                        <TableCell>{attendee.name ?? "N/A"}</TableCell>
                                      }
                                      {visibleColumns.phone &&
                                        <TableCell>{attendee.phoneNumber ?? "N/A"}</TableCell>
                                      }
                                      {visibleColumns.email &&
                                        <TableCell>{attendee.email ?? "N/A"}</TableCell>
                                      }
                                      {visibleColumns.offer &&
                                        <TableCell>{attendee.offer ?? "N/A"}</TableCell>
                                      }
                                      {visibleColumns.financeType &&
                                        <TableCell>{attendee.financeType && attendee.financeType.trim() !== "" ? capitalizeText(attendee.financeType) : "N/A"}</TableCell>
                                      }
                                      {visibleColumns.settlement &&
                                        <TableCell>{attendee.settlement ? "Yes" : "No"}</TableCell>
                                      }
                                      {visibleColumns.termite &&
                                        <TableCell>{attendee.termite ? "Yes" : "No"}</TableCell>
                                      }
                                      {visibleColumns.otherConditions &&
                                        <TableCell>{attendee.otherConditions && attendee.otherConditions.trim() !== "" ? attendee.otherConditions : "N/A"}</TableCell>
                                      }
                                    </TableRow>
                                  ))}
                                </TableBody>
                                <TableFooter>
                                  <TableRow>
                                    <TableCell colSpan={8} align="left">
                                      <IconButton onClick={handleMenuOpen}>
                                        <SettingsIcon />
                                      </IconButton>
                                      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
                                        <Box sx={{ padding: '8px 16px' }}>
                                          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                            Column Visibility
                                          </Typography>
                                        </Box>
                                        <Divider />
                                        {Object.keys(visibleColumns).map((column) => (
                                          <MenuItem key={column}>
                                            <Checkbox
                                              checked={visibleColumns[column]}
                                              onChange={() => toggleColumn(column)}
                                            />
                                            {column.charAt(0).toUpperCase() + column.slice(1)}
                                          </MenuItem>
                                        ))}
                                      </Menu>
                                    </TableCell>
                                  </TableRow>
                                </TableFooter>
                              </Table>
                            </TableContainer>
                          </>
                        ) : (
                          <Box
                            sx={{
                              width: '100%',
                              my: 6,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6">No attendees yet checked in.</Typography>
                            <Button
                              variant="containedAccent"
                              onClick={toggleUpdateForm}
                              sx={{
                                width: '30%'
                              }}
                            >
                              Update Event
                            </Button>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                )}
              </Grid>
              {(formData.eventType === 2) && propertyData && (
                <Grid item xs={12} md={4} padding={2}>
                  <HomeOpenQR
                    propertyDetails={{
                      homeOpenId: eventId,
                      price: propertyData.price,
                      address: formData.address,
                      listingId: formData.propertyId,
                      openHouseDate: `${dayjs(formData.start).format('MMM DD, YYYY')}, ${dayjs(formData.start).format('hh:mm a')}`,
                      bedrooms: propertyData.bedrooms,
                      bathrooms: propertyData.bathrooms,
                      parkingSpots: propertyData.parkings,
                      images: propertyData.images,
                      priceParameter: propertyData.priceParameter,
                    }}
                    agentDetails={agentDetails}
                    showSnackbar={handleShowSnackbar}
                  />
                </Grid>
              )}
            </Grid>
          </Paper>
        </Container >
        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </>
    );
  };
};

export default EditEventForm;
