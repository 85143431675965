// Import uploadBytesResumable instead of uploadBytes
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  listAll,
} from "firebase/storage";
import { storage } from "../environments/FirebaseConfig";

const getResizedFileName = (fileName, suffix) => {
  const dotIndex = fileName.lastIndexOf(".");
  if (dotIndex === -1) {
    // No extension
    return fileName + suffix;
  } else {
    const name = fileName.substring(0, dotIndex);
    const extension = fileName.substring(dotIndex);
    return name + suffix + extension;
  }
};

const getResizedImageUrl = async (
  storageRef,
  maxRetries = 5,
  delay = 3000
) => {
  for (let i = 0; i < maxRetries; i++) {
    try {
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      if (error.code === "storage/object-not-found") {
        // Resized image not ready yet, wait and retry
        await new Promise((resolve) => setTimeout(resolve, delay));
      } else {
        // Some other error occurred
        throw error;
      }
    }
  }
  throw new Error(
    "Failed to get resized image download URL after maximum retries"
  );
};

export const uploadImage = (file, propertyId, progressCallback) => {
  return new Promise((resolve, reject) => {
    const storageRef = ref(storage, `properties/${propertyId}/` + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Calculate progress
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (progressCallback) {
          progressCallback(progress);
        }
      },
      (error) => {
        reject(error);
      },
      async () => {
        // Upload completed successfully
        try {
          const resizedFileName = getResizedFileName(file.name, "_1920x1080");
          const resizedRef = ref(
            storage,
            `properties/${propertyId}/` + resizedFileName
          );
          const downloadURL = await getResizedImageUrl(resizedRef, 5, 2000);
          resolve(downloadURL);
        } catch (error) {
          reject(error);
        }
      }
    );
  });
};

export const uploadMultipleImages = (
  files,
  propertyId,
  progressCallback
) => {
  const totalBytes = files.reduce((acc, file) => acc + file.size, 0);
  const bytesTransferredArray = new Array(files.length).fill(0);
  const urls = [];

  return new Promise((resolve, reject) => {
    const uploadPromises = files.map((file, index) => {
      return new Promise((resolveUpload, rejectUpload) => {
        const storageRef = ref(storage, `properties/${propertyId}/` + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Update the bytes transferred for this specific file
            bytesTransferredArray[index] = snapshot.bytesTransferred;

            // Sum up the bytes transferred for all files
            const totalBytesTransferred = bytesTransferredArray.reduce(
              (a, b) => a + b,
              0
            );

            // Calculate overall progress
            const overallProgress =
              (totalBytesTransferred / totalBytes) * 100;

            // Update the progress bar
            if (progressCallback) {
              progressCallback(overallProgress);
            }
          },
          (error) => {
            rejectUpload(error);
          },
          async () => {
            try {
              const resizedFileName = getResizedFileName(
                file.name,
                "_1920x1080"
              );
              const resizedRef = ref(
                storage,
                `properties/${propertyId}/` + resizedFileName
              );
              const downloadURL = await getResizedImageUrl(resizedRef, 5, 3000);
              urls.push(downloadURL);
              resolveUpload();
            } catch (error) {
              rejectUpload(error);
            }
          }
        );
      });
    });

    Promise.all(uploadPromises)
      .then(() => resolve(urls))
      .catch((error) => reject(error));
  });
};

export const deleteImage = async (imageUrl) => {
  try {
    const imageRef = ref(storage, imageUrl);
    await deleteObject(imageRef);
  } catch (error) {
    throw new Error("Failed to delete image");
  }
};

export const deletePropertyImages = async (propertyId) => {
  const propertyRef = ref(storage, `properties/${propertyId}`);

  try {
    // List all files in the property folder
    const listResults = await listAll(propertyRef);

    // Create an array of delete promises for all files
    const deletePromises = listResults.items.map((itemRef) => {
      return deleteObject(itemRef);
    });

    // Wait for all delete operations to complete
    await Promise.all(deletePromises);

    console.log(`All images in property ${propertyId} have been deleted.`);
  } catch (error) {
    console.error("Failed to delete images:", error);
    throw new Error("Failed to delete images");
  }
};

/**
 * Upload a PDF or Word doc to Firebase Storage,
 * returning the download URL.
 */
export const uploadDocFile = (file, propertyId, progressCallback) => {
  return new Promise((resolve, reject) => {
    const storageRef = ref(storage, `properties/${propertyId}/docs/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (progressCallback) {
          progressCallback(progress);
        }
      },
      (error) => {
        reject(error);
      },
      async () => {
        // Upload complete
        try {
          const downloadURL = await getDownloadURL(storageRef);
          resolve(downloadURL);
        } catch (error) {
          reject(error);
        }
      }
    );
  });
};

/**
 * Upload multiple doc files at once, returning an array of URLs.
 */
export const uploadMultipleDocs = (files, propertyId, progressCallback) => {
  const totalBytes = files.reduce((acc, file) => acc + file.size, 0);
  const bytesTransferredArray = new Array(files.length).fill(0);
  const urls = [];

  return new Promise((resolve, reject) => {
    const uploadPromises = files.map((file, index) => {
      return new Promise((resolveUpload, rejectUpload) => {
        const storageRef = ref(storage, `properties/${propertyId}/docs/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            bytesTransferredArray[index] = snapshot.bytesTransferred;
            const totalBytesTransferred = bytesTransferredArray.reduce((a, b) => a + b, 0);
            const overallProgress = (totalBytesTransferred / totalBytes) * 100;
            if (progressCallback) {
              progressCallback(overallProgress);
            }
          },
          (error) => rejectUpload(error),
          async () => {
            try {
              const downloadURL = await getDownloadURL(storageRef);
              urls.push(downloadURL);
              resolveUpload();
            } catch (error) {
              rejectUpload(error);
            }
          }
        );
      });
    });

    Promise.all(uploadPromises)
      .then(() => resolve(urls))
      .catch((error) => reject(error));
  });
};

export const deleteDoc = async (docUrl) => {
  try {
    const docRef = ref(storage, docUrl);
    await deleteObject(docRef);
  } catch (error) {
    throw new Error("Failed to delete document");
  }
};

export const deletePropertyDocs = async (propertyId) => {
  const propertyRef = ref(storage, `properties/${propertyId}/docs`);

  try {
    const listResults = await listAll(propertyRef);

    const deletePromises = listResults.items.map((itemRef) => {
      return deleteObject(itemRef);
    });

    await Promise.all(deletePromises);

    console.log(`All documents in property ${propertyId} have been deleted.`);
  }
  catch (error) {
    console.error("Failed to delete documents:", error);
    throw new Error("Failed to delete documents");
  }
};