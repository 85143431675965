import React from 'react';

function MaintenanceNavbar() {
  return (
    <nav style={{
      backgroundColor: 'var(--primary-color)',
      color: 'var(--text-color)',
      padding: '0.5rem 1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}>
      <div style={{ fontWeight: 'bold' }}>MyApp (Maintenance Mode)</div>
      {/* Additional Navbar content can go here */}
    </nav>
  );
}

export default MaintenanceNavbar;
