import CryptoJS from 'crypto-js';

const HASH_SECRET_KEY = process.env.REACT_APP_HASH_SECRET_KEY;
const FIXED_IV = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_HASH_FIXED_IV);  // 16 bytes for AES

export function fixedHashUid(uid) {
    const key = CryptoJS.enc.Utf8.parse(HASH_SECRET_KEY);
    const encrypted = CryptoJS.AES.encrypt(uid.toString(), key, { iv: FIXED_IV });
    return encodeURIComponent(encrypted.ciphertext.toString(CryptoJS.enc.Base64url));
}

export function fixedDehashUid(hashedUid) {
    try {
        const decoded = CryptoJS.enc.Base64url.parse(decodeURIComponent(hashedUid));
        const encrypted = CryptoJS.lib.CipherParams.create({
            ciphertext: decoded,
        });

        const key = CryptoJS.enc.Utf8.parse(HASH_SECRET_KEY);
        const decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: FIXED_IV });

        return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
        console.error('Decryption failed:', error);
        return null;
    }
}

export function hashUid(uid) {
  // Convert UID to string and encrypt it using AES
  const ciphertext = CryptoJS.AES.encrypt(uid.toString(), HASH_SECRET_KEY).toString();
  // Encode the ciphertext to make it URL-safe
  return encodeURIComponent(ciphertext);
}

export function dehashUid(hashedUid) {
  // Decode the hashed UID from URL encoding
  const decodedHash = decodeURIComponent(hashedUid);
  // Decrypt the ciphertext
  const bytes = CryptoJS.AES.decrypt(decodedHash, HASH_SECRET_KEY);
  const originalUid = bytes.toString(CryptoJS.enc.Utf8);
  return originalUid;
}
